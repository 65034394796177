<template>
    <div class="index" style="height: 100%;">
      <el-container class="conta" >
        <el-header style="background-color: #fff;height: 70px;">
            <indexheader />
        </el-header>
        <el-container>
          <el-aside class="left index"><indexleft/></el-aside>
          
          <el-main class="index" style="padding: 0 10px 0 0;">
            <indexmain/>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </template>
  
  <script  setup>
import { useStore } from 'vuex';

import indexheader from '../components/index/indexheader.vue';
import indexleft from '../components/index/indexleft.vue';
import indexmain from '../components/index/indexmain.vue';
import router from '../router/index';

const store = useStore()

// console.log(store.state.user.userdata.time, new Date().getTime());
const time = ()=>{
  setTimeout(()=>{
    if (store.state.user.userdata.time + 1000*1800 < new Date().getTime()) {
        alert('登录时间超时，请重新登录')
        router.push({ path: '/login' })
        
    }else{
      time()
    }

  },60000)
}

setTimeout(()=>{
    if (store.state.user.userdata.time + 1000*1800 < new Date().getTime()) {
        alert('登录时间超时，请重新登录')
        router.push({ path: '/login' })
    }
    time()
  },60000)

</script>
  <style scoped>
  .left{
    max-width: 230px;
    /* border-radius:10px; */
    background-color:#212526;
    /* height: calc(100% - 20px); */
    /* margin: 10px; */
    padding: 10px;
}
.index{
    background-color: rgb(179, 245, 255);
    /* height: 820px; */
    /* overflow: auto;
    min-height: 800px; */
}
.conta{
    height: 100%;
}
  </style>