<template>
    <div style="height: 100%;background-color: #212526;border-radius: 10px;">
        <div style="width: 100%;padding-top: 20px;">
            <img style="width: 100%;" src="../../assets/logo2.png" alt="">
              <div class="username">
                {{store.state.user.userdata.username}}
              </div>
        </div>
        <div class="rigth">
          <a class="bt" v-bind:class="[click==3 ? 'click':'','']"  @click="num(3)"  href="javascript:;"> 
                <div class="btdata" >
                    <div class="ic">
                      <el-icon><UserFilled /></el-icon>
                    </div>
                    <div class="text">
                        个人中心
                    </div>
                </div>
            </a>
          <a class="bt" v-bind:class="[click==1 ? 'click':'','']"  @click="num(1)"  href="javascript:;"> 
                <div class="btdata" >
                    <div class="ic">
                        <el-icon><Document /></el-icon>
                    </div>
                    <div class="text">
                        文档缓存
                    </div>
                </div>
            </a>
          <a class="bt" v-bind:class="[click==0 ? 'click':'','']"  @click="num(0)"  href="javascript:;"> 
              <div class="btdata" >
                  <div class="ic">
                    <el-icon><Setting /></el-icon>
                  </div>
                  <div class="text">
                      修改密码
                  </div>
              </div>
          </a>
          <a class="bt" v-bind:class="[click==2 ? 'click':'','']"  @click="num(2)"  href="javascript:;"> 
              <div class="btdata" >
                  <div class="ic">
                    <el-icon><Shop /></el-icon>
                  </div>
                  <div class="text">
                      购买服务
                  </div>
              </div>
          </a>
          <a class="bt" v-bind:class="[click==4 ? 'click':'','']"  @click="num(4)"  href="javascript:;"> 
              <div class="btdata" >
                  <div class="ic">
                    <el-icon><Lock /></el-icon>
                  </div>
                  <div class="text">
                      Q Q绑定
                  </div>
              </div>
          </a>
        </div>
    </div>
</template>
  
  <script  setup>
import { ref } from 'vue';

import { useStore } from 'vuex';

import {
  Document,
  Lock,
  Setting,
  Shop,
  UserFilled,
} from '@element-plus/icons-vue';

const store = useStore()

  const click = ref(store.state.click)

  const num =(e)=>{
    click.value = e
    store.commit('changeclick', {
       num:e
      })
  }


  
  
</script>
  <style scoped>
  .right{
    width: 100%;
  }
  .username{
    text-align: center;
    margin-bottom: 20px;
  }
  .bt{
    width: 100%;
    height: 50px;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    color: #fff;
    display: flex;
    font-size: 18px;
  }
  .btdata{
    margin: 0 auto;
    display: flex;
  }
  .ic{
    margin-top: 2px;
  }
  .text{
    margin-left: 20px;
    width: 80px;
    text-align: left;
  }
  .click{
    background: linear-gradient(to right, blue, rgb(141, 8, 158));
    border-radius: 10px;
  }
  </style>