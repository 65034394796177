
<template>
  <div class="change">
        <div class="fromcont">
          <div class="loginfrom">
              <div class="xiugai">
                  修改密码
              </div>
              <div class="xiugaiform">
                  <el-form :model="form" label-position="right" label-width="10px" size="large">
                    <el-form-item>
                        <el-input class="input" v-model="form.user"  placeholder="请输入用户名">
                    </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input class="input" v-model="form.pass" type="password" placeholder="请输入旧密码">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input class="input" v-model="form.newpass" type="password"  placeholder="请输入新密码">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input class="input" v-model="form.new" type="password"  placeholder="再次输入新密码">
                        </el-input>
                    </el-form-item>
                    
                    <el-form-item>
                    <el-button class="loginbutton input" type="primary" @click="onSubmit">修改</el-button>
                    </el-form-item>
                </el-form>
              </div>
          
          </div>
        </div>
    </div>
</template>
  
<script setup>
import { reactive } from 'vue';

import {
  ElMessage,
  ElMessageBox,
} from 'element-plus';

import apinew from '../../api/apinew';

// console.log(store.state);
// do not use same name with ref


const form = reactive({
  user: '',
  pass: '',
  newpass: '',
  new:''
})

const onSubmit = () => {
  apinew.login({
      "username": form.user,  
      "password": form.pass
    }).then((req) => {
    console.log(req.code);
    if (req.code == 400) {
      open()
    }else{
      if (req.data.code==0) {
        const strCheck = /^(?![0-9]*$)(?![a-zA-Z]*$)[a-zA-Z0-9]{6,20}$/
        if (form.new==form.newpass) {
            if (strCheck.test(form.newpass)) {
              let log = {
                "username": form.user, 
                "password": form.pass, 
                "xinpwd": form.newpass ,
              }
              // 修改
              apinew.set(log).then((req) => {
                console.log(req);
                if (req.data.code == -1) {
                  open()
                } else{
                  open2()
                }
              }).catch(() => {
                console.log(123);
                open()
              })
            }else{
              newpassopen()
            }
          }else{
            openpass()
          }
      }else{
        open()
      }
    }
   
  })

}

const init = ()=>{
  form.user = ''
  form.pass = ''
  form.newpass = ''
  form.new = ''
}
// 提示
const open = () => {
  ElMessageBox.alert('请输入正确的密码或账号！', '密码或账号错误', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: 'OK',
    callback: () => {
      init()
      ElMessage({
        type: 'error',
        message: `账号密码错误`,
      })
    },
  })
}
// 提示
const newpassopen = () => {
  ElMessageBox.alert('密码由6-20位英文字母、数字或符号组成!', '新密码输入有误！', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: 'OK',
    callback: () => {
      init()
      ElMessage({
        type: 'error',
        message: `修改失败`,
      })
    },
  })
}
  
// 判断修改密码提示
const openpass = () => {
  ElMessageBox.alert('请重新输入新密码！', '新密码输入有误！', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: 'OK',
    callback: () => {
      init()
      ElMessage({
        type: 'error',
        message: `修改失败`,
      })
    },
  })
}
  
// 修改成功提示
const open2 = () => {
  ElMessageBox.alert('修改完成！', '修改完毕', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: 'OK',
    callback: () => {
      init()
      ElMessage({
        type: 'success',
        message: `修改成功`,
      })
    },
  })
}
</script>

  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .header{
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background-color:#354c6b; */
  }
  .logo{
    height: 70%;
    display: flex;
    transform:translateY(15px) translateX(20px);
  }
  .change{
    /* transform: translateY(7px); */
    margin-bottom: 50px;
    /* margin-top:120px ; */
    padding-top: 120px;
  }
  .outlogin{
    transform:translateY(10px)  translateX(-20px);
    width: 40px;
  }
  .exit{
    display: flex;
    justify-content: space-between;
    color: rgb(189, 189, 189);
    font-size: 20px;
    width: 250px;
    line-height: 60px;
    padding-right: 20px;
  }
  .shu{
    width: 1px;
    height: 30px;
    background-color: azure;
    margin-top: 15px;
  }
  .xiugai {
      width: 120px;
      font-size: 26px;
      margin: 0 auto;
      /* margin-bottom: 120px; */
  }
  .xiugaiform{
      width: 300px;
      margin: 0 auto;
      margin-top: 20px;
  }

  .loginfrom{
    background-color: rgb(224, 255, 255);
    width: 500px;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid rgb(202, 255, 255);
    margin:  0 auto;
    box-shadow: 0 0 10px #ccc;
  }
  .loginbutton {
  width: 100%;
  }

  /* .logo {
  width: 300px;
  transform: translateY(20px) translateX(20px);
  
  } */
  .gohome{
  position: absolute;
  right: 20px;
  top: 30px;
  }
  .floottext {
  text-align: center;
  transform: translateY(50px) translateX(60px);
  color: aliceblue;
  }
  
  </style>
  