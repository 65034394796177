<template>
    <div class="indexpersonal">
        <div class="index" v-if="invest">
            <div class="indexlist">
                <div class="indexid">
                    {{store.state.user.userdata.username}}
                </div>
                <div class="indexmoney">
                    余额: <span>123</span> <span class="invest" @click="goinvest"><a href="javascript:;">充值入口</a> </span>
                </div>
            </div>
            <div class="indexbody">
                <div class="bodyheader">
                    <div class="bodyheadertitle">
                        每日消耗数据
                        <div class="bodyheaderdate">
                            <el-date-picker
                                v-model="value"
                                type="daterange"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                :default-time="defaultTime"
                                @change="datechange"
                            />  
                        </div>
                    </div>
                </div>
                <div class="bodylist"> 
                    <el-table :data="tableData" style="width: 100%" :row-style="{color:'#000000',fontSize: '16px'}" :header-cell-style="{ textAlign: 'center',fontSize: '16px',color:'var(--el-color-primary)' }">
                        <el-table-column prop="date" label="时间" width="280" align="center"/>
                        <el-table-column prop="name" label="消耗" align="center"/>
                    </el-table>
                </div>
                <div class="bodybottom">
                    <el-pagination
                        v-model:current-page="currentPage1"
                        :page-size="14"
                        :small="small"
                        :disabled="disabled"
                        :background="background"
                        layout="total, prev, pager, next"
                        :total="selectdatelist.length"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        />
                </div>
            </div>
        </div>
        <div class="indexinvest" v-else>
            <div class="investdata">
                 <div class="investbody">
                    <div class="investbodyheader">
                        充值中心
                    </div>
                    <div class="investbodybody">
                        <div class="investbodylist">
                            <div class="listmont" v-bind:class="[moent==1 ? 'clickmonet':'','']">
                                <a class="lista" href="javascript:;" @click="moent=1">100元</a>
                            </div>
                            <div class="listmont" v-bind:class="[moent==2 ? 'clickmonet':'','']"> 
                                <a class="lista" href="javascript:;" @click="moent=2">200元</a>
                            </div>
                            <div class="listmont" v-bind:class="[moent==3 ? 'clickmonet':'','']">
                                <a class="lista" href="javascript:;" @click="moent=3">500元</a>
                            </div>
                            <div class="listmont" v-bind:class="[moent==4 ? 'clickmonet':'','']">
                                <a class="lista" href="javascript:;" @click="moent=4">1000元</a>
                            </div>
                        </div>
                        <div >
                            <el-radio-group class="radio" v-model="radio1" text-color="#ccc">
                                <el-radio class="radiobtn" label="1" size="large" >微信支付</el-radio>
                                <el-radio class="radiobtn" label="2" size="large" >支付宝支付</el-radio>
                            </el-radio-group>
                        </div>
                        <div>
                            <el-row class="mb-4">
                                <el-button class="btnupdate" type="primary" @click="clickinvest">确认支付</el-button>
                            </el-row>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</template>
    
<script setup>
import { ref } from 'vue';

import { useStore } from 'vuex';

const store = useStore()
const radio1 = ref('1')
const moent = ref(0)
const value = ref('')
const pagevalue = ref(0)
const invest = ref(true)

const tableData =  ref([])

const datalist = ref([
    {
    date: '1683475200',
    name: '1',
  },
    {
    date: '1683475200',
    name: '1',
  },
  {
    date: '1683475200',
    name: '2',
  },
  {
    date: '1683475200',
    name: '3',
  },
  {
    date: '1683475200',
    name: '4',
  },
  {
    date: '1683475200',
    name: '5',
  },
  {
    date: '1683475200',
    name: '6',
  },
  {
    date: '1683475200',
    name: '7',
  },
  {
    date: '1683475200',
    name: '8',
  },
  {
    date: '1683475200',
    name: '9',
  },
  {
    date: '1683475200',
    name: '10',
  },
  {
    date: '1683475200',
    name: '11',
  },
  {
    date: '1683475200',
    name: '12',
  },
  {
    date: '1683475200',
    name: '13',
  },
  {
    date: '1683475200',
    name: '14',
  },
  {
    date: '1683475200',
    name: '14',
  },
    {
    date: '1683388800',
    name: '1',
  },
  {
    date: '1683388800',
    name: '2',
  },
  {
    date: '1683388800',
    name: '3',
  },
  {
    date: '1683388800',
    name: '4',
  },
  {
    date: '1683388800',
    name: '5',
  },
  {
    date: '1683388800',
    name: '6',
  },
  {
    date: '1683388800',
    name: '7',
  },
  {
    date: '1683388800',
    name: '8',
  },
  {
    date: '1683388800',
    name: '9',
  },
  {
    date: '1683388800',
    name: '10',
  },
  {
    date: '1683388800',
    name: '11',
  },
  {
    date: '1683388800',
    name: '12',
  },
  {
    date: '1683388800',
    name: '13',
  },
  {
    date: '1683388800',
    name: '14',
  },
  {
    date: '1683388800',
    name: '14',
  },
])

const selectdatelist = ref([])

let timestart = ref(0)
let timeend = ref(new Date().getTime()/1000) 
const datechange =(value)=>{
    if (value!=null) {
        // console.log(changetime(value[1].getTime()));
        // console.log(changetime(value[0].getTime()));
        timestart.value = value[0].getTime()/1000
        timeend.value = value[1].getTime()/1000+3600*24
        selectdate(timestart.value,timeend.value)
        tableData.value = []
        pagedata(tableData,0,1)
    }else{
         timestart.value = 0
         timeend.value = new Date().getTime()/1000
         selectdate(timestart.value,timeend.value)
         tableData.value = []
         pagedata(tableData,0,1)
    }
   
}
const selectdate = (time1,time2)=>{
    selectdatelist.value = []
    for (let i = 0; i < datalist.value.length; i++) {
        if (time1<= Number(datalist.value[i].date) && Number(datalist.value[i].date) <time2) {
            selectdatelist.value.push({
                name:datalist.value[i].name,
                date:changetime(datalist.value[i].date)
            })
        }
    }
}

// 翻页更新数据
const handleCurrentChange =(value)=>{
    // console.log(value);
    pagevalue.value = value
    tableData.value = []
    pagedata(tableData,pagevalue.value - 1,value)
}

// 页面数据
const pagedata = (arr,pagenum,pagelength)=>{
    for (let i = 14*pagenum; i < 14*pagelength; i++) {
        if (selectdatelist.value.length >= i) {
            arr.value.push(selectdatelist.value[i])
        }else{
            return
        }
    }
}


const changetime = (time)=>{
  const date = new Date(time * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  const D = date.getDate() + ' ';
  return (Y + M + D)
}

selectdate(timestart.value,timeend.value)
pagedata(tableData,0,1)



const goinvest=()=>{
    invest.value = false
}

///////////////////////////////////////////// invest
const clickinvest = ()=>{
    console.log(moent.value,radio1.value);
    invest.value = true
}


</script>
    
<style>

.indexlist{
    width: 100%;
    height: 60px;
    padding-left: 25px;
    /* margin-top: 10px; */
    padding-top: 5px;
}
.indexid{
    color: black;
    font-size: 16px;
    font-weight: 900;
}
.indexmoney{
    font-size: 16px;
    color: #868686;
}
.invest{
    background-color: #def7ff;
    font-size: 12px;
    padding: 2px;
    display: inline-block;
    width: 80px;
    text-align: center;
    color:var(--el-color-primary);
    border-radius: 8px;
    border: 1px solid #ccc;
    
}
.indexbody{
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
    line-height: 60px;
}
.bodyheader{
    padding-left: 20px;
}

.bodyheadertitle{
    font-size: 20px;
    font-weight: 900;
    color:var(--el-color-primary);
}
.bodyheaderdate{
    display: inline-block;
    margin-left: 50px;
}
.el-pagination{
    justify-content: center;
}

.investdata{
    width: 100%;
    height: 100%;
    padding-top: 140px;
}
.investbody{
    width: 800px;
    height: 500px;
    background: #daf9ff;
    margin: 0 auto;
    /* margin-top: 140px; */
    padding-top: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px #555555;
}
.investbodyheader{
    font-size: 30px;
    width: 100%;
    text-align: center;
    padding-top: 30px;
    color: var(--el-color-primary);
}
.nvestbodybody{
    height: 100%;
}
.investbodylist{
    display: flex;
    justify-content: center;
}
.listmont{
    width: 120px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    border: 1px solid var(--el-color-primary);
    margin: 20px;
    border-radius: 10px;
    
}

.listmont .lista{
    display: inline-block;
    color: var(--el-color-primary);
    width: 100%;
    height: 100%;
}
.clickmonet{
    background: var(--el-color-primary);
    border: 1px solid var(--el-color-primary);
}
.clickmonet .lista{
    color: #fff;
}
.mb-4{
    justify-content: center;
    margin-top: 30px;
}

.btn{
    font-size: 20px;
    width: 120px;
    height: 50px;
    border-radius: 10px;
}
.radio{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.radiobtn{
    color: #000000;
    border: 1px solid #000;
    border-radius: 5px;
    padding:25px 20px;
}
.el-radio__label{
    font-size: 20px !important; 
}

.btnupdate{
    width: 130px;
    height: 45px;
    font-size: 18px;
    margin-top: 20px;
}
</style>