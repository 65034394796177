
<template>
    <div class="qqindex">
        <div class="qq">
            <div class="qqheader">
                QQ绑定
            </div>
            <div class="qqbody" v-if="change">
                <el-form :model="form" label-position="right" style="max-width: 400px; margin: 0 auto;" size="large">
                    <el-form-item>
                        <el-input class="qqinput" v-model="form.qquser"  placeholder="请输入QQ号">
                    </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input class="qqinput" v-model="form.qqpass" type="password" placeholder="请输入密码">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="qqbody" v-else>
               <div class="qqimg">
                    <img src="../../assets/img/login/img1.png" alt="">
               </div>
            </div>
            <div class="qqbottom">
                <div class="bottoma">
                    <a href="javascript:;" @click="change = false">扫码绑定</a>
                    <a href="javascript:;" @click="change = true">账号绑定</a>
                </div>
            </div>

        </div>

    </div>
</template>
    
<script setup>
import {
  reactive,
  ref,
} from 'vue';

const form = reactive({
  qquser:'',
  qqpass:''
})
const change = ref(true)
  
</script>
  
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .qqindex{
        padding-top: 120px;

    }
    .qq{
        width: 800px;
        height: 450px;
        background-color: rgb(236, 253, 255);
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0 0 10px  rgb(153, 153, 153);
    }
    .qqheader{
        font-size: 24px;
        text-align: center;
        padding-top: 60px;
        padding-bottom: 20px;
    }
    .qqinput{
        height: 50px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 18px;
    }
    .qqbottom{
        margin: 0 auto;
        height: 40px;
        width: 300px;
        line-height: 40px;
    }
    .bottoma{
        padding-top: 20px;
        display: flex;

        justify-content: space-between;
    }
    .bottoma{
        font-size: 22px;
    }
    .qqimg{
        width: 200px;
        margin: 0 auto;
    }
</style>
