<template>
    <div class="header">
      <div class="logo">
        <img src="../../assets/logo.png" alt="">
      </div>
      <div class="change">
        <div class="exit">
          <div class="kf" style="display: flex;">
            <div style="margin-top: 4px;">
              <el-icon ><Avatar /></el-icon>
            </div>
            <div  style="margin-left: 10px;">
              <a @click="dialogTableVisible = true" href="javascript:;">
                联系客服
              </a>
            </div>
          </div>
          <div class="shu">
          </div>
          <div class="out" style="display: flex;">
            <div style="margin-top: 4px;">
              <el-icon><SwitchButton /></el-icon>
            </div>
            <div style="margin-left: 10px;">
              <a @click="outlogin" href="javascript:;">
                退出
              </a>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogTableVisible" title="扫描客服二维码">
      <div style="width: 100%;height: 100%; margin:0 auto;"><img style="width: 100%;height: 100%;" src="../../assets/tc.png" alt=""> </div>
    </el-dialog>
    <!-- <el-dialog v-model="dialogTableVisible" title="修改密码">
      <div class="change">
        <div class="fromcont">
          <div class="loginfrom">
              <div class="xiugai">
                  修改密码
              </div>
              <div class="xiugaiform">
                  <el-form :model="form" label-position="right" label-width="10px" size="large">
                  <el-form-item>
                      <el-input class="input" v-model="form.user"  placeholder="请输入用户名">
                  </el-input>
                  </el-form-item>
                  <el-form-item>
                      <el-input class="input" v-model="form.pass" type="password" placeholder="请输入旧密码">
                      </el-input>
                  </el-form-item>
                  <el-form-item>
                      <el-input class="input" v-model="form.newpass" type="password"  placeholder="请输入新密码">
                      </el-input>
                  </el-form-item>
                  <el-form-item>
                      <el-input class="input" v-model="form.new" type="password"  placeholder="再次输入新密码">
                      </el-input>
                  </el-form-item>
                  
                  <el-form-item>
                  <el-button class="loginbutton input" type="primary" @click="onSubmit">修改</el-button>
                  </el-form-item>
              </el-form>
              </div>
          
          </div>
        </div>
    </div>
    </el-dialog> -->
  </template>
  
  <script setup>
// import { ElMessageBox } from 'element-plus';

import { ref } from 'vue';

import {
  Avatar,
  SwitchButton,
} from '@element-plus/icons-vue';

import router from '../../router/index';

const dialogTableVisible = ref(false)

const outlogin =()=>{
    router.push({ path: '/login' })
  }

// console.log(store.state);
// do not use same name with ref
</script>

  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .header{
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background-color:#354c6b; */
  }
  .logo{
    height: 70%;
    display: flex;
    transform:translateY(15px) translateX(20px);
  }
  .change{
    /* transform: translateY(7px); */
    /* margin-bottom: 20px; */
    margin-top: 4px;
  }
  .outlogin{
    transform:translateY(10px)  translateX(-20px);
    width: 40px;
  }
  .exit{
    display: flex;
    justify-content: space-between;
    color: rgb(189, 189, 189);
    font-size: 20px;
    width: 250px;
    line-height: 60px;
    padding-right: 24px;
  }
  .shu{
    width: 1px;
    height: 20px;
    background-color: rgb(175, 175, 175);
    margin-top: 20px;
  }
  .xiugai {
      width: 120px;
      font-size: 26px;
      margin: 0 auto;
      /* margin-bottom: 120px; */
  }
  .xiugaiform{
      width: 300px;
      margin: 0 auto;
      margin-top: 20px;
  }

  .loginbutton {
  width: 100%;
  }

  /* .logo {
  width: 300px;
  transform: translateY(20px) translateX(20px);
  
  } */
  .gohome{
  position: absolute;
  right: 20px;
  top: 30px;
  }
  .floottext {
  text-align: center;
  transform: translateY(50px) translateX(60px);
  color: aliceblue;
  }
  
  </style>
  