<template>
  <div class="buyindex">
    <div class="buypage" v-if="buy">
      <div class="buyheader">
        <h2>可选套餐</h2>
      </div>
      
      <div class="buylist">
          <div class="listdata" style="padding: 20px" v-for="(item, index) in datelist" v-bind:key="index">
            <el-card class="box-card">
              <template #header>
                <div class="card-header">
                  <span>{{ item.title }}</span>
                </div>
              </template>
                <div v-for="(list, index) in item.datalist" :key="index" class="text item">
                  {{ list }}
                </div>
            </el-card>
            <div class="bottom">
                  <div class="price"> <span class="priceicon">￥</span>{{item.price}}</div>
                  <el-button type="primary" class="buybutton" @click="buyclick(item.id)">立即订阅</el-button>
                </div>
          </div>
      </div>
    </div>
    <div class="paypage" v-else>
      <div class="payindex">
        <div class="buyheader">
          <h2>在线支付</h2>
        </div>
        <div class="paybody">
          <div class="paybodyheader">
            <span>请选择支付方式</span>
          </div>
          <div class="paybodybody">
            <div class="pay">
              <div class="ewm">
                <div class="ewmimg"> 
                  <img src="../../assets/img/buy/wx.png" alt="">
                </div>
              </div>
              <div class="paybodybottom">
                微信支付
              </div>
            </div>
            <div class="pay">
              <div class="ewm">
                <div class="ewmimg"> 
                  <img src="../../assets/img/buy/zfb.png" alt="">
                </div>
              </div>
              <div class="paybodybottom">
                支付宝支付
              </div>
            </div>
            
          </div>
          <div class="gobuy">
            <el-button type="primary" class="buybutton" @click="buy = true">返回商店</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
 
   
  </template>
  
  <script setup>
import { ref } from 'vue';

const buy = ref(true)

const datelist = ref([])

datelist.value = [{
  id:1,
  title:'QQ机器人套餐',
  datalist:[
    '1.此套餐需要提供一个QQ,给这个QQ接入AI',
    '2.此套餐包含199/月产品费用以及800元流量费用',
    '3.余额可以累计,产品费用不能累计',
    '4.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:2,
  title:'企业微信机器人套餐',
  datalist:[
    '1.此套餐需要提供一个企业微信,给这个企业微信接入AI',
    '2.此套餐包含199/月产品费用以及800元流量费用',
    '3.余额可以累计,产品费用不能累计',
    '4.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:3,
  title:'微信公众号机器人套餐',
  datalist:[
    '1.此套餐需要提供一个微信公众号,给这个微信公众号接入AI',
    '2.此套餐包含199/月产品费用以及800元流量费用',
    '3.余额可以累计,产品费用不能累计',
    '4.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:4,
  title:'chatGPT网页版套餐',
  datalist:[
    '1.此套餐包含199/月产品费用以及800元流量费用',
    '2.余额可以累计,产品费用不能累计',
    '3.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:4,
  title:'chatGPT网页版套餐',
  datalist:[
    '1.此套餐包含199/月产品费用以及800元流量费用',
    '2.余额可以累计,产品费用不能累计',
    '3.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:4,
  title:'chatGPT网页版套餐',
  datalist:[
    '1.此套餐包含199/月产品费用以及800元流量费用',
    '2.余额可以累计,产品费用不能累计',
    '3.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:4,
  title:'chatGPT网页版套餐',
  datalist:[
    '1.此套餐包含199/月产品费用以及800元流量费用',
    '2.余额可以累计,产品费用不能累计',
    '3.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:4,
  title:'chatGPT网页版套餐',
  datalist:[
    '1.此套餐包含199/月产品费用以及800元流量费用',
    '2.余额可以累计,产品费用不能累计',
    '3.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:4,
  title:'chatGPT网页版套餐',
  datalist:[
    '1.此套餐包含199/月产品费用以及800元流量费用',
    '2.余额可以累计,产品费用不能累计',
    '3.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
},{
  id:4,
  title:'chatGPT网页版套餐',
  datalist:[
    '1.此套餐包含199/月产品费用以及800元流量费用',
    '2.余额可以累计,产品费用不能累计',
    '3.购买前有任何问题可以先咨询客服'
  ],
  price:'999',
}]

const buyclick =(e)=>{
  console.log(e);
  buy.value = false
}

</script>
  
  <style>
  .buyindex{
    width: 100%;
    padding-top: 5px ;
  
  }
  .buyheader{
    height: 50px;
    padding-left: 20px;
    border-bottom: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    box-shadow: 5px 5px 5px #e4e4e4;
    color:var(--el-color-primary);
  }
  .buylist{
    /* width: 33%; */
    /* overflow: auto;
    max-height: 760px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .listdata{
    width: 33%;
  }
  .box-card{
    border-radius: 20px;
    height: 100%;
  }
  .card-header span{
    display: inline-block;
    width: 100%;
    font-weight: 900;
    text-align: center;
    font-size: 20px;
  }
  .time {
    font-size: 12px;
    color: #999;
  }
  .item{
    margin: 10px 0;
    color: black;
    font-size: 16px;
  }
  
  .bottom {
    margin-top: 13px;
    /* line-height: 12px; */
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    border-top: 1px solid rgb(214, 214, 214);
    padding-top: 15px;
    border-radius: 20px;
    transform: translate(0,-100px);
    padding: 20px;
    box-shadow: 0 0 10px #ccc;
  }
  
  .button {
    padding: 0;
    min-height: auto;
  }
  
  .image {
    width: 100%;
    display: block;
  }
  .price{
    font-size: 30px;
    color:var(--el-color-primary) ;
    /* width: 200px;
    text-align: center; */
  }
  .priceicon{
    font-size: 20px;
  }
  .buybutton{
    font-size: 20px;
    height: 44px;
    border-radius: 6px;
    width: 120px;
  }

  .paybody{
    width: 700px;
    height: 500px;
    background-color:#d8f6ff;
    margin: 0 auto;
    margin-top: 100px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px #999;

  }
  .paybodyheader{
    width: 100%;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    color:var(--el-color-primary);
    font-weight: 600;
  }
  .paybodybody{
    display: flex;
    justify-content: space-between;
  }
  .pay{
    padding: 50px;
    padding-bottom: 20px;
  }
  .ewm{
    width: 250px;
    height: 250px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid var(--el-color-primary);
  }
  .ewmimg{
    width: 200px;
    height: 200px;
    transform: translate(25px,25px);
  }
  .paybodybottom{
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-top:20px;
    font-weight: 900;
  }
  .gobuy{
    text-align: center;
  }
  </style>