<template>
  <div class="maindata">
      <div v-if="store.state.click == 1">
          <indexdom/>
      </div>
      <div v-else-if="store.state.click == 0">
          <indexchange></indexchange>
      </div>
      <div v-else-if="store.state.click == 2">
          <indexbuy></indexbuy>
      </div>
      <div v-else-if="store.state.click == 3">
          <personal></personal>
      </div>
      <div v-else-if="store.state.click == 4">
          <indexqq></indexqq>
      </div>
     
  </div>
</template>

<script  setup>
import { useStore } from 'vuex';

import indexbuy from './indexbuy.vue';
import indexchange from './indexchange.vue';
import indexdom from './indexdom.vue';
import personal from './indexpersonal.vue';
import indexqq from './indexqq.vue';

const store = useStore()


</script>
<style scoped>
/* .main{
  width: 100% ;
  height: 100%;
 
} */

::v-deep .el-popper {
      max-width: 800px;
  }
.maindata{
  width: calc(100% - 10px);
  height: calc(100% - 20px);
  /* min-height: 800px; */
  /* max-height: 820px; */
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 820px;
  /* max-height: 1020px; */
  /* padding-bottom: 30px; */
  overflow: auto;  
}

</style>