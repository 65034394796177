<template>
        <div>
            <div class="header">
                <div style="display: flex;">
                    <div class="date">
                        <div class="text">
                            日期 :
                        </div>
                        <div class="block">
                            <el-date-picker
                                v-model="value2"
                                type="daterange"
                                unlink-panels
                                range-separator="To"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :shortcuts="shortcuts"
                                :size="size"
                                @change="getdatepicker"
                            />
                        </div>
                    </div>
                    <div class="date">
                        <div class="text">
                            条件 :
                        </div>
                        <div>
                            <el-input v-model="input" placeholder="输入问题" />
                        </div>
                    </div>
                    <div class="date">
                        <div class="button">
                            <a class="bt click"  href="javascript:;"> 
                                <div class="btdata" >
                                    <div class="text" @click="clickserve">
                                        搜索
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="button">
                            <a class="bt click"  href="javascript:;"> 
                                <div class="btdata" >
                                    <div class="text" @click="clickdownload" v-if="true">
                                        下载
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main">
            
                <div class="data">
                    <el-table
                        ref="multipleTableRef"
                        :data="wenti"
                        style="width: calc(100% - 20px);margin-left: 20px;"
                        stripe 
                        @selection-change="handleSelectionChange"
                        @select-all="selectall"
                        @select="select"
                        :row-key="getRow"
                        v-loading="loading"
                    >
                        <el-table-column reserve-selection type="selection" width="55" />
                        <el-table-column property="name" label="问题" width="420" show-overflow-tooltip/>
                        <el-table-column property="address" label="答案" show-overflow-tooltip/>
                   
                        <el-table-column label="日期" width="120">
                        <template #default="scope">{{ scope.row.date }}</template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page">
                    <div class="pagedata">
                        <div class="demo-pagination-block">
                            <el-pagination
                            v-model:current-page="currentPage1"
                            :page-size="15"
                            :small="small"
                            :disabled="disabled"
                            :background="background"
                            layout="prev, pager, next, jumper,total"
                            :total="total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            />
               
                        </div>
                    </div>
                
                </div>

            </div>
        </div>
</template>
  
<script  setup>
import {
  getCurrentInstance,
  ref,
} from 'vue';

import axios from 'axios';
import {
  ElMessage,
  ElMessageBox,
  ElTable,
} from 'element-plus';

import apinew from '../../api/apinew';

const multipleTableRef = ref(null);

const loading = ref(true)

const size = ref<'default' | 'large' | 'small'>('default')

const value2 = ref('')
const input = ref('')
const inputdata = ref('') 

let upend = ref(Math.floor(new Date().getTime()/1000))
let upstart = ref(0)

let page = ref(1)

const currentPage1 = ref(1)

const shortcuts = [
  {
    text: '一周前',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
  
      return [start, end]
    },
  },
  {
    text: '一个月前',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '三个月前',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
]

let total = ref(1000)

let newstart = ref(0)
let newend = ref(Math.floor(new Date().getTime()/1000))


const wenti = ref([])

const getdatepicker = (value)=>{
  console.log(value);
  if (value==null) {
    upstart.value = 0
    upend.value = Math.floor(new Date().getTime()/1000)
  }else{
    upstart.value = Math.floor((value[0].getTime() )/1000 ) 
    upend.value = Math.floor((value[1].getTime() + 3600 * 1000 * 24)/ 1000) 
  }
  
}

const changetime = (time)=>{
  const date = new Date(time * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  const D = date.getDate() + ' ';
  return (Y + M + D)
}

apinew.fuzzySearch({
  "startDate": newstart.value, 
  "endDate":newend.value, 
  "keyword": inputdata.value,  
  "iskeyword": true, 
  "pageNum": 1, 
  "pageSize": 15 
}).then((req)=>{
  console.log(req.data.total);
  loading.value = false
  total.value = req.data.total
  wenti.value = []
  for (let i = 0; i < req.data.data.length; i++) {
    // console.log(wenti.value);
   const time =req.data.data[i].create_at
    wenti.value.push({
      id:req.data.data[i].id,
      date: changetime(time),
      name:req.data.data[i].user_msg,
      address:req.data.data[i].ai_msg
    })
  }
 
})


const handleCurrentChange =(value)=>{
  page.value = value
  loading.value = true
  apinew.fuzzySearch({
    "startDate": newstart.value, 
    "endDate":newend.value, 
    "keyword": inputdata.value,  
    "iskeyword": true, 
    "pageNum": page.value, 
    "pageSize": 15 
  }).then((req)=>{
    wenti.value = []
    loading.value = false
    for (let i = 0; i < req.data.data.length; i++) {
      const time = req.data.data[i].create_at
      wenti.value.push({
        id:req.data.data[i].id,
        date:changetime(time),
        name:req.data.data[i].user_msg,
        address:req.data.data[i].ai_msg
      })
    }
  })
}

let download = ref([])

let list = new Map()


const selectall =(value)=>{
  console.log(value);
  download.value = []
  for (let i = 0; i < value.length; i++) {
    download.value.push(value[i].id)
  }
  list.set(1,download.value)
}
const select =(value)=>{
  console.log(value);
  download.value = []
  for (let i = 0; i < value.length; i++) {
    download.value.push(value[i].id)
  }
  list.set(1,download.value)
}

let listids = ref([])


const { proxy }  = getCurrentInstance();  



// axios({
//     method: "get",   //请求方式
//     url: "https://node-test.qiaomukeji.com/lingsheng2304/chat/testDownload1",   //请求接口
//     responseType: "blob",  //不可省略
//   });
const clickdownload = ()=>{
  // axios.defaults.baseURL = "https://node-test.qiaomukeji.com/lingsheng2304/";
 
  //  axios({
  //    method: "post",
  //    data:{'ids':[1,2,3]},   //请求方式
  //    url: "chat/returnString",   //请求接口
  //    responseType: "blob",  //不可省略
  //  }).then((res)=>{
  //     console.log(res);
  //     const blob = new Blob([res.data],{type:"text/markdown"})  // res.data 为接口返回的数据，依实例而行
  //     console.log(blob);
  //     const href = URL.createObjectURL(blob)  // 生成一个 Blob URL
  //     console.log(href);
  //     // // 下载文件
  //     const fileName = '1.md'  // 文件名+后缀
  //     const listNode = document.createElement("a")
  //     listNode.download = fileName 
  //     listNode.style.display = "none"
  //     listNode.href = href // 文件流生成的url
  //     document.body.appendChild(listNode)
  //     listNode.click()  // 模拟在按钮上实现一次鼠标点击
  //     href.revokeObjectURL(listNode.href)  // 释放 URL 对象
  //     document.body.removeChild(listNode)  // 移除 a 标签
  //  });
  


  listids.value = []
  for (let key of list.values()) {
    for (let i = 0; i < key.length; i++) {
      listids.value.push(key[i])
    }
  }
    if (listids.value.length >0) {
      let ids ={
        ids:listids.value
      }
      if (loading.value == false) {
        loading.value = true
        axios({
        method: "post",
        data:ids,   //请求方式
        url: "https://node-test.qiaomukeji.com/lingsheng2304/chat/downloadInfo",   //请求接口
        // responseType: "blob",  //不可省略
        }).then((res)=>{
            console.log(res);
            loading.value = false
            let blob = new Blob([res.data.data],{type:"text/markdown"})  // res.data 为接口返回的数据，依实例而行
            console.log(blob);
            let href = URL.createObjectURL(blob)  // 生成一个 Blob URL
            console.log(href);
            // // 下载文件
            let namefile = new Date().getTime()
            let fileName = `${namefile}.md`  // 文件名+后缀
            let listNode = document.createElement("a")
            listNode.download = fileName 
            listNode.style.display = "none"

            // listNode.id = namefile
            listNode.href = href // 文件流生成的url
            document.body.appendChild(listNode)
            listNode.click()  // 模拟在按钮上实现一次鼠标点击

            URL.revokeObjectURL(href); // 释放 URL 对象
            document.body.removeChild(listNode)  // 移除 a 标签

            // 清除选择的内容
            proxy.$refs.multipleTableRef.clearSelection()
            // 清空map
            list.clear()

        });
      }else{
        ElMessage.error('请勿重复点击下载!')
      }
   
      // apinew.download(ids).then((req)=>{
      //   console.log(req);

      //   proxy.$refs.multipleTableRef.clearSelection()

      //   let link = document.createElement("a");
      //   link.href = req.data.data[0]
      //   link.style.display = 'none'
      //   document.body.appendChild(link)
      //   link.click()
      //   document.body.removeChild(link);
      //   list.clear()

      //   // const blob = new Blob([req.data.data])
      //   // const fileName = '1.md'
      //   // const link = document.createElement('a')
      //   // link.download = fileName
      //   // link.style.display = 'none'
      //   // link.href = URL.createObjectURL(blob)
      //   // document.body.appendChild(link)
      //   // link.click()
      //   // URL.revokeObjectURL(link.href)
      //   // document.body.removeChild(link)
      
      // })
    }else{
      open()
    //   console.log('没有选择内容');
    }
  

}

const getRow =(row) =>{
    return row.id
  }




const clickserve = ()=>{

  inputdata.value = input.value
  newstart.value =  upstart.value
  newend.value =  upend.value
  loading.value = true
  apinew.fuzzySearch({
    "startDate": newstart.value, 
    "endDate":newend.value, 
    "keyword": inputdata.value,  
    "iskeyword": true, 
    "pageNum": 1, 
    "pageSize": 15 
  }).then((req)=>{
    loading.value = false


    proxy.$refs.multipleTableRef.clearSelection()


    total.value = req.data.total
    currentPage1.value = 1
    download.value = []
    list.set(1,download.value)
    wenti.value = []
    for (let i = 0; i < req.data.data.length; i++) {
      const time = req.data.data[i].create_at
      wenti.value.push({
        id:req.data.data[i].id,
        date:changetime(time),
        name:req.data.data[i].user_msg,
        address:req.data.data[i].ai_msg
      })
    }
  })
}

// 未选择下载内容失败提示
const open = () => {
  ElMessageBox.alert('暂未选择下载内容内容！', '请选择下载内容！', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: '确认',
    callback: () => {
      ElMessage({
        type: 'error',
        message: `下载失败`,
      })
    },
  })
}

  
</script>
  <style scoped>


  ::v-deep .el-popper {
        max-width: 800px;
    }

  .date{
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
    height: 40px;
    line-height: 30px;
  }
  /* .datebt{
    display: flex;
    margin-top: 17px;
    margin-left: 10px;
    height: 40px;
    line-height: 30px;
  } */
  .text{
    font-size: 16px;
    margin-right: 10px;
  }

  .button{
    margin-left: 30px;
  }
  .bt{
    width: 100%;
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    color: white;
  }
  .btdata{
    margin: 0 auto;
    /* display: flex; */
    width: 100px;
  }
  .text{
    margin-left: 10px;
  }
  .click{
    background: linear-gradient(to right, blue, rgb(151, 8, 158));
    border-radius: 10px;
  }
 
  .pagedata{
    margin: 30px;
    /* margin-left: 30%; */
    justify-content: center;
  }
  </style>